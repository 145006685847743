<template>
  <span>
    <div class="header-container" :class="{ 'header-container-inner': selectedMenu.main == 1 }">
      <div class="logo-container">
        <img alt="Logo" class="img-logo ponter-cur" src="./../assets/Logo.svg" @click="redirectTo(1)" />
      </div>
      <div class="toggle-btn">
        <label for="customeTotgel">
          <b-icon class="" icon="menu-button-wide"></b-icon>
        </label>
      </div>
      <input type="checkbox" id="customeTotgel" />
      <div class="menu-container d-flex flex-row-reverse">
        <div class="d-flex profile-inf">
          <div class="profile-name-heade primary-color">
            <span>{{ shortName }}</span>
          </div>
          <div class="pf-menu profile-menu">
            <div class="dropdown-custome dd-menu-items profile-dd">
              <button class="btn btn-secondary dropdown-toggle custome-btn" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                {{ fullName }}
                <b-icon icon="caret-down-fill" aria-hidden="true" class="profile-da"></b-icon>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li @click="redirectTo(5)">User Profile</li>
                <li class="dd-last-li" @click="logout">Logout</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="notification-con" v-b-modal.notificationModal>
          <b-icon class="notification-icon" icon="bell"></b-icon>
          <span class="position-absolute top-0 start-100 badge rounded-pill bg-danger notification-count notfn-cnt"
            :class="{ 'hide-notf-cn': notificationCount == 0 }">
            {{ notificationCount }}
          </span>
        </div>
        <div class="mm-menu">
          <div class="dropdown-custome dd-menu-items maturity-dd">
            <button class="btn btn-secondary dropdown-toggle custome-btn ma-btn" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" :disabled="selectedMenu.main == 3"
              :class="{
                'sd-color': selectedMenu.main == 3,
                'sub-menu-item-active': selectedMenu.main == 3
              }">
              Reports
              <b-icon icon="caret-down-fill" aria-hidden="true" class="active-down sd-color"
                v-if="selectedMenu.main == 3"></b-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li @click="redirectTo(8)">Gap Closure</li>
              <li @click="redirectTo(27)">Gap Closure Monthly</li>
              <li @click="redirectTo(29)">Not-Sustained</li>
              <li @click="redirectTo(11)">Consolidated View</li>
              <li @click="redirectTo(9)">Bottleneck Practice</li>
              <li @click="redirectTo(10)">Spiderweb View</li>
              <li @click="redirectTo(13)">Constraint Group OEE</li>
              <li @click="redirectTo(18)">Machine Step Audit</li>
              <li @click="redirectTo(19)">OEE Regional</li>
              <li class="dd-last-li" @click="redirectTo(20)">Machine KPI</li>
            </ul>
          </div>
        </div>
        <div class="mm-menu">
          <div class="dropdown-custome dd-menu-items maturity-dd">
            <button class="btn btn-secondary dropdown-toggle custome-btn ma-btn" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" :disabled="selectedMenu.main == 4"
              :class="{
                'sd-color': selectedMenu.main == 4,
                'sub-menu-item-active': selectedMenu.main == 4
              }">
              Machine Replication
              <b-icon icon="caret-down-fill" aria-hidden="true" class="active-down sd-color"
                v-if="selectedMenu.main == 4"></b-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li @click="redirectTo(12)">OEE Dashboard</li>
              <li @click="redirectTo(14)">Step Audit By Pillar</li>
              <li @click="redirectTo(15)">Step Audit By Machine</li>
              <li v-if="$oidc.userProfile.roles.includes(roles.INDUSTRYENGINEER)" @click="redirectTo(16)">Upload Machine
                File</li>
              <li v-if="$oidc.userProfile.roles.includes(roles.INDUSTRYENGINEER)" @click="redirectTo(22)">Upload KPI File
              </li>
              <li
                v-if="$oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER)"
                @click="redirectTo(17)">
                Constrained Machines Group
              </li>
              <li class="dd-last-li"
                v-if="$oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER)"
                @click="redirectTo(21)">
                Machine Classification
              </li>
            </ul>
          </div>
        </div>

        <div class="mm-menu"
          v-if="$oidc.userProfile.roles.includes(roles.POENERGYUSER) || $oidc.userProfile.roles.includes(roles.POENERGYADMIN)">
          <div class="dropdown-custome dd-menu-items maturity-dd">
            <button class="btn btn-secondary dropdown-toggle custome-btn ma-btn" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" :disabled="selectedMenu.main == 5"
              :class="{
                'sd-color': selectedMenu.main == 5,
                'sub-menu-item-active': selectedMenu.main == 5
              }">
              Energy
              <b-icon icon="caret-down-fill" aria-hidden="true" class="active-down sd-color"
                v-if="selectedMenu.main == 5"></b-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li @click="redirectTo(23)">Energy Zero Loss Calculator</li>
              <li @click="redirectTo(26)">Energy Loss Submission Status</li>
              <li @click="redirectTo(24)">Energy Savings Project Input</li>
              <li class="dd-last-li" @click="redirectTo(25)">Energy Savings Project List</li>
            </ul>
          </div>
        </div>

        <div class="mm-menu">
          <div class="dropdown-custome dd-menu-items maturity-dd">
            <button class="btn btn-secondary dropdown-toggle custome-btn ma-btn" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" :disabled="selectedMenu.main == 2"
              :class="{
                'sd-color': selectedMenu.main == 2,
                'sub-menu-item-active': selectedMenu.main == 2
              }">
              Maturity Model
              <b-icon icon="caret-down-fill" aria-hidden="true" class="active-down sd-color"
                v-if="selectedMenu.main == 2"></b-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li @click="redirectTo(2)">Assessment</li>
              <li @click="redirectTo(3)">Forecast Setting</li>
              <li @click="redirectTo(28)"
                v-if="$oidc.userProfile.roles.includes(roles.PLANTPOLEADER) || $oidc.userProfile.roles.includes(roles.REGIONALPOLEADER) || $oidc.userProfile.roles.includes(roles.GLOBALPILLARLEADER)">
                AOP Targets
              </li>
              <li @click="redirectTo(4)">Actions</li>
              <li class="dd-last-li" @click="redirectTo(6)"
                v-if="!($oidc.userProfile.roles.includes(roles.CORPORATE) && $oidc.userProfile.roles.length <= 1)">
                Governance</li>
            </ul>
          </div>
        </div>
        <div class="mm-menu">
          <div class="dropdown-custome dd-menu-items maturity-dd">
            <button @click="redirectTo(7)" class="btn btn-secondary dropdown-toggle custome-btn ma-btn" type="button"
              :class="{
                'sd-color': selectedMenu.main == 7
              }">
              Education
            </button>
          </div>
        </div>
        <!-- <div class="ed-menu headr-bar-menu font-family-m cursor-pointer" :class="{
          'ed-menu-active': selectedMenu.main == 7
        }" @click="redirectTo(7)">
          Education
        </div> -->
      </div>
    </div>
    <nav class="sub-menu" v-if="selectedMenu.main != 1">
      <ul v-if="selectedMenu.main == 2">
        <li v-if="selectedMenu.main == 2" @click="redirectTo(2)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 1,
            'sub-menu-item-active': selectedMenu.subMenu == 1
          }">Assessment</span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 1 }" class="opacity-zero" />
        </li>
        <li v-if="selectedMenu.main == 2" @click="redirectTo(3)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 2,
            'sub-menu-item-active': selectedMenu.subMenu == 2
          }">Forecast Setting</span>
          <hr class="opacity-zero" :class="{ 'active-tab-hr': selectedMenu.subMenu == 2 }" />
        </li>
        <li v-if="selectedMenu.main == 2" @click="redirectTo(28)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 5,
            'sub-menu-item-active': selectedMenu.subMenu == 5
          }">AOP Targets</span>
          <hr class="opacity-zero" :class="{ 'active-tab-hr': selectedMenu.subMenu == 5 }" />
        </li>
        <li v-if="selectedMenu.main == 2" @click="redirectTo(4)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 3,
            'sub-menu-item-active': selectedMenu.subMenu == 3
          }">
            Actions
          </span>
          <hr class="opacity-zero" :class="{ 'active-tab-hr': selectedMenu.subMenu == 3 }" />
        </li>
        <li
          v-if="selectedMenu.main == 2 && !($oidc.userProfile.roles.includes(roles.CORPORATE) && $oidc.userProfile.roles.length <= 1)"
          @click="redirectTo(6)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 4,
            'sub-menu-item-active': selectedMenu.subMenu == 4
          }">Governance</span>
          <hr class="opacity-zero" :class="{ 'active-tab-hr': selectedMenu.subMenu == 4 }" />
        </li>
      </ul>
      <div id="reports-container" v-if="selectedMenu.main == 3">
        <div class="arrows">
          <img alt="more" v-if="disableLeftArrow" class="attch-icon" src="@/assets/arrow-left.svg" @click="leftShift()" />
          <img alt="more" v-if="!disableLeftArrow" class="attch-icon" src="@/assets/arrow-left-disabled.svg" />
        </div>
        <div id="reports" class="scroll" v-if="selectedMenu.main == 3">
          <div class="single-item" v-for="report in reportsList" :key="report.redirectNumber"
            @click="redirectTo(report.redirectNumber)">
            <div class="sub-menu-item" :class="{
              'sd-color': selectedMenu.subMenu == report.subMenu,
              'sub-menu-item-active': selectedMenu.subMenu == report.subMenu
            }">
              {{ report.reportName }}
            </div>
            <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == report.subMenu }" class="opacity-zero" />
          </div>
        </div>
        <div class="arrows">
          <img alt="more" v-if="disableRightArrow" class="attch-icon" src="@/assets/arrow-right.svg"
            @click="rightShift()" />
          <img alt="more" v-if="!disableRightArrow" class="attch-icon" src="@/assets/arrow-right-disabled.svg" />
        </div>
      </div>

      <ul v-if="selectedMenu.main == 4">
        <li v-if="selectedMenu.main == 4" @click="redirectTo(12)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 1,
            'sub-menu-item-active': selectedMenu.subMenu == 1
          }">
            OEE Dashboard
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 1 }" class="opacity-zero" />
        </li>
        <li v-if="selectedMenu.main == 4" @click="redirectTo(14)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 3,
            'sub-menu-item-active': selectedMenu.subMenu == 3
          }">
            Step Audit By Pillar
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 3 }" class="opacity-zero" />
        </li>
        <li v-if="selectedMenu.main == 4" @click="redirectTo(15)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 4,
            'sub-menu-item-active': selectedMenu.subMenu == 4
          }">
            Step Audit By Machine
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 4 }" class="opacity-zero" />
        </li>

        <li v-if="selectedMenu.main == 4 && $oidc.userProfile.roles.includes(roles.INDUSTRYENGINEER)"
          @click="redirectTo(16)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 2,
            'sub-menu-item-active': selectedMenu.subMenu == 2
          }">
            Upload Machine File
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 2 }" class="opacity-zero" />
        </li>
        <li v-if="selectedMenu.main == 4 && $oidc.userProfile.roles.includes(roles.INDUSTRYENGINEER)"
          @click="redirectTo(22)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 7,
            'sub-menu-item-active': selectedMenu.subMenu == 7
          }">
            Upload KPI File
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 7 }" class="opacity-zero" />
        </li>
        <li @click="redirectTo(17)"
          v-if="selectedMenu.main == 4 && ($oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER))">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 5,
            'sub-menu-item-active': selectedMenu.subMenu == 5
          }">
            Constrained Machines Group
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 5 }" class="opacity-zero" />
        </li>

        <li
          v-if="selectedMenu.main == 4 && ($oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER))"
          @click="redirectTo(21)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 6,
            'sub-menu-item-active': selectedMenu.subMenu == 6
          }">
            Machine Classification
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 6 }" class="opacity-zero" />
        </li>
      </ul>

      <ul v-if="selectedMenu.main == 5">
        <li v-if="selectedMenu.main == 5" @click="redirectTo(23)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 1,
            'sub-menu-item-active': selectedMenu.subMenu == 1
          }">
            Energy Zero Loss Calculator
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 1 }" class="opacity-zero" />
        </li>

        <li v-if="selectedMenu.main == 5" @click="redirectTo(26)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 4,
            'sub-menu-item-active': selectedMenu.subMenu == 4
          }">
            Energy Loss Submission Status
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 4 }" class="opacity-zero" />
        </li>

        <li v-if="selectedMenu.main == 5" @click="redirectTo(24)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 2,
            'sub-menu-item-active': selectedMenu.subMenu == 2
          }">
            Energy Savings Project Input
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 2 }" class="opacity-zero" />
        </li>

        <li v-if="selectedMenu.main == 5" @click="redirectTo(25)">
          <span class="sub-menu-item" :class="{
            'sd-color': selectedMenu.subMenu == 3,
            'sub-menu-item-active': selectedMenu.subMenu == 3
          }">
            Energy Savings Project List
          </span>
          <hr :class="{ 'active-tab-hr': selectedMenu.subMenu == 3 }" class="opacity-zero" />
        </li>
      </ul>

      <ul v-if="selectedMenu.main == 7">
        <li>
          <span class="sub-menu-item" style="height: 5.5rem"></span>
        </li>
      </ul>
    </nav>
    <Notification v-if="notificationCount" :notificationArray="notificationArray"
      :navigateToEditAction="navigateToEditAction" :clearNotification="clearNotification"
      :clearAllNotification="clearAllNotification" />
  </span>
</template>
<script>
import { mapGetters } from "vuex";
import Role from "../common/constants/roles";
import Api from "../common/constants/api";
import ApiCalls from "../common/api/apiCalls";
import Notification from "../components/common/Notification.vue";
import moment from "moment";
import { PublicClientApplication } from "@azure/msal-browser";
export default {
  name: "Header",
  components: {
    Notification
  },
  data() {
    return {
      userName: "",
      roles: Role,
      notificationCount: 0,
      notificationArray: [],
      reportsList: [
        {
          subMenu: 1,
          redirectNumber: 8,
          reportName: "Gap Closure"
        },
        {
          subMenu: 9,
          redirectNumber: 27,
          reportName: "Gap Closure Monthly"
        },
        {
          subMenu: 10,
          redirectNumber: 29,
          reportName: "Not Sustained"
        },
        {
          subMenu: 2,
          redirectNumber: 11,
          reportName: "Consolidated View"
        },
        {
          subMenu: 3,
          redirectNumber: 9,
          reportName: "Bottleneck Practice"
        },
        {
          subMenu: 4,
          redirectNumber: 10,
          reportName: "Spiderweb View"
        },
        {
          subMenu: 5,
          redirectNumber: 13,
          reportName: "Constraint Group OEE"
        },
        {
          subMenu: 6,
          redirectNumber: 18,
          reportName: "Machine Step Audit"
        },
        {
          subMenu: 7,
          redirectNumber: 19,
          reportName: "OEE Regional"
        },
        {
          subMenu: 8,
          redirectNumber: 20,
          reportName: "Machine KPI"
        }
      ],
      scrollWidth: 0,
      scrollHorizontal: 0,
      disableLeftArrow: false,
      disableRightArrow: true
    };
  },
  computed: {
    ...mapGetters(["selectedMenu", "loggedInUserData"]),
    shortName() {
      if (this.$oidc.userProfile) {
        return this.$oidc.userProfile.name[0] + "" + this.$oidc.userProfile.family_name[0];
      }
    },
    fullName() {
      if (this.$oidc.userProfile) {
        return (
          this.$oidc.userProfile.given_name.slice(0, 1) +
          this.$oidc.userProfile.given_name.slice(1).toLowerCase() +
          " " +
          this.$oidc.userProfile.family_name.slice(0, 1) +
          this.$oidc.userProfile.family_name.slice(1).toLowerCase()
        );
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 1,
      subMenu: 0
    });
  },
  created() {
    if (this.$oidc.userProfile) {
      this.userName = this.$oidc.userProfile.given_name + " " + this.$oidc.userProfile.family_name;
    }
  },
  beforeMount() {
    ApiCalls.httpCallDetails(Api.NOTIFICATIONLIST, "get").then((dataJSON) => {
      let data = dataJSON.data;
      if (data) {
        this.notificationArray = data.allNotifications;
        if (data.actionTypeData.offtrackActionData) {
          for (const element of data.actionTypeData.offtrackActionData) {
            element["notificationOffTRackType"] = true;
            element["notificationType"] = "Off-track";
            element["message"] =
              "For Plant " +
              element.plantName +
              ", Pillar " +
              element.pillarName +
              ", Practice " +
              element.practiceNumber +
              "." +
              element.actionTitle +
              ", action's committed date is " +
              moment(new Date(element.targetCompletionDate)).format("DD-MMM-YYYY");
            this.notificationArray.push(element);
          }
        }
        this.notificationCount = this.notificationArray.length;
      }
    });
  },
  methods: {
    leftShift() {
      const conent = document.querySelector("#reports");
      this.scrollHorizontal -= 100;

      conent.scroll(this.scrollHorizontal, 0);
      console.log(conent.scrollLeft, this.scrollHorizontal);
      this.disableLeftArrow = this.scrollHorizontal !== 0
      this.disableRightArrow = (conent.scrollLeft >= this.scrollHorizontal)
    },
    rightShift() {
      const conent = document.querySelector("#reports");
      this.scrollHorizontal += 100;

      conent.scroll(this.scrollHorizontal, 0);
      console.log(conent.scrollLeft, this.scrollHorizontal);
      this.disableLeftArrow = this.scrollHorizontal !== 0
      this.disableRightArrow = (conent.scrollLeft >= this.scrollHorizontal)
    },
    navigateToEditAction(item, index, close) {
      close();
      switch (item.notificationType) {
        case "Governance-Normal":
          this.$router.push("/governance");
          break;
        case "Governance-Validation-Plant":
          this.$router.push("/governance");
          break;
        case "Governance-Window-Alert":
          this.$router.push("/governance");
          break;
        case "Off-track":
          this.$router.push(
            "/add-new-action/" +
            item.plantId +
            "/" +
            item.pillarId +
            "/" +
            item.principleId +
            "/" +
            item.practiceId +
            "/" +
            item.actionId +
            "/" +
            item.levelId +
            "/" +
            item.pillarName +
            "/" +
            item.plantName +
            "/" +
            parseInt(item.actionStatusId) +
            "/" +
            "editAction"
          );
          break;
        case "Action-Added":
          this.$router.push(
            "/add-new-action/" +
            item.plantId +
            "/" +
            item.pillarId +
            "/" +
            item.principleId +
            "/" +
            item.practiceId +
            "/" +
            item.actionId +
            "/" +
            item.levelId +
            "/" +
            item.pillarName +
            "/" +
            item.plantName +
            "/" +
            parseInt(item.actionStatusId) +
            "/" +
            "editAction"
          );
          break;
        case "Self-Assessment":
          this.$router.push(
            "/add-new-action/" +
            item.plantId +
            "/" +
            item.pillarId +
            "/" +
            item.principleId +
            "/" +
            item.practiceId +
            "/" +
            item.actionId +
            "/" +
            item.levelId +
            "/" +
            item.pillarName +
            "/" +
            item.plantName +
            "/" +
            parseInt(item.actionStatusId) +
            "/" +
            "editAction"
          );
          break;
        case "Action-Completed":
          this.$router.push(
            "/take-assessment/" +
            item.plantId +
            "/" +
            item.pillarId +
            "/" +
            item.pillarName +
            "/" +
            "assessment" +
            "/" +
            item.plantName +
            ", " +
            item.regionName +
            "/" +
            item.levelId +
            "/" +
            item.principleId
          );
          break;
        case "Self-Assessment-NotSustained":
          this.$router.push(
            "/take-assessment/" +
            item.plantId +
            "/" +
            item.pillarId +
            "/" +
            item.pillarName +
            "/" +
            "assessment" +
            "/" +
            item.plantName +
            ", " +
            item.regionName +
            "/" +
            item.levelId +
            "/" +
            item.principleId
          );
          break;
        case "Governance-MajorChange":
          this.$router.push("/view-assessment/" + item.plantId + "/" + item.pillarId + "/" + item.plantName + ", " + item.regionName);
          break;
        case "Target-Setting":
          this.$router.push("/forecast-setting");
          break;
        default:
          break;
      }

      setTimeout(() => {
        this.$router.go();
      }, 300);
    },
    clearNotification(notification, index, close) {
      let url = Api.DIMISSNOTIFICATION;
      if (notification.notificationOffTRackType) {
        url += "?ActionId=" + notification.actionId + "&Type=Action-Offtrack";
      } else {
        url += "?NotificationId=" + notification.notificationId + "&Type=" + notification.notificationType;
      }
      ApiCalls.httpCallDetails(url, "get").then((dataJSON) => {
        this.notificationArray.splice(index, 1);
        this.notificationCount = this.notificationArray.length;
        if (this.notificationArray.length == 0) close();
      });
    },
    clearAllNotification(close) {
      ApiCalls.httpCallDetails(Api.DIMISSALLNOTIFICATION, "get").then((dataJSON) => {
        this.notificationArray = [];
        this.notificationCount = this.notificationArray.length;
        close();
      });
    },
    redirectTo(tab) {
      if (tab != 2) {
        this.$store.dispatch("plantSelectedInAsmtHome", 0);
        this.$store.dispatch("prevSelectedWorkCenter", 0);
        this.$store.dispatch("pillarPrevSelected", 0);
      }
      if (tab != 3) {
        this.$store.dispatch("plantSelectedInTargetHome", 0);
      }
      if (tab == 6) {
        this.$store.dispatch("pillarSelectedInGov", 0);
        this.$store.dispatch("principalSelectedGov", 0);
        this.$store.dispatch("pillarNameSelectedInGov", "");
        this.$store.dispatch("levelSelectedGov", "");
        if (
          !(
            this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) ||
            this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
            this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) ||
            this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER) ||
            this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) ||
            this.$oidc.userProfile.roles.includes(this.roles.USER)
          )
        )
          return;
      }
      let element = document.querySelectorAll("#dropdownMenuButton1");

      element.forEach((el) => bootstrap.Dropdown.getOrCreateInstance(el).hide());
      switch (tab) {
        case 1:
          this.$router.push("/dashboard");
          break;
        case 2:
          this.$router.push("/assessment");
          break;
        case 3:
          this.$router.push("/forecast-setting");
          break;
        case 4:
          let plantId = "";
          if (this.loggedInUserData.plantId) plantId = this.loggedInUserData.plantId;
          else plantId = this.loggedInUserData.regionName ? 0 : 1;
          this.$router.push("/action/action-home-page/" + plantId + "/-1");
          break;
        case 5:
          this.$router.push("/profile");
          break;
        case 6:
          this.$router.push("/governance");
          break;
        case 7:
          this.$router.push("/educationHomePage");
          break;
        case 8:
          this.$router.push("/gap-closure");
          break;
        case 9:
          this.$router.push("/bottleneck");
          break;
        case 10:
          this.$router.push("/maturity-level-graph");
          break;
        case 11:
          this.$router.push("/action-tracker-view");
          break;
        case 12:
          this.$router.push("/machine-assessment");
          break;
        case 13:
          this.$router.push("/machine-oee-report");
          break;
        case 14:
          this.$router.push("/machine-action-pillar");
          break;
        case 15:
          this.$router.push("/machine-action-machine");
          break;
        case 16:
          this.$router.push("/machine-upload");
          break;
        case 17:
          this.$router.push("/constraint-machines-group");
          break;
        case 18:
          this.$router.push("/machine-assessment-report");
          break;
        case 19:
          this.$router.push("/oee-regional-report");
          break;
        case 20:
          this.$router.push("/machine-kpi-report");
          break;
        case 21:
          this.$router.push("/machine-classification");
          break;
        case 22:
          this.$router.push("/kpi-upload");
          break;
        case 23:
          this.$router.push("/energy-loss-calculator");
          break;
        case 24:
          this.$router.push("/energy-loss-project-list");
          break;
        case 25:
          this.$router.push("/energy-loss-project-summary");
          break;
        case 26:
          this.$router.push("/energy-loss-submission");
          break;
        case 27:
          this.$router.push("/gap-closure-plant");
          break;
        case 28:
          this.$router.push("/aop-target");
          break;
        case 29:
          this.$router.push("/not-sustained");
          break;
        default:
          break;
      }
    },
    logout() {
      let config = this.$store.getters.stateMSAL.msalConfig;
      const myMsal = new PublicClientApplication(config);
      let homeAccountId = JSON.parse(sessionStorage.getItem("userProfile")).oid + "." + JSON.parse(sessionStorage.getItem("userProfile")).tid;
      // you can select which account application should sign out
      const logoutRequest = {
        account: myMsal.getAccountByHomeId(homeAccountId)
      };

      myMsal.logoutRedirect(logoutRequest);
    }
  }
};
</script>

<style scoped>
.custome-btn {
  width: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  background-color: darkgoldenrod;
  min-height: 5rem;
  color: #fff;
  align-items: center;
  padding-right: 4.5rem;
  padding-left: 4.5rem;
  z-index: 100;
}

.header-container-inner {
  position: sticky;
  top: 0;
  width: 100%;
}

.logo-container {
  width: 10%;
}

.menu-container {
  width: 75%;
  align-items: center;
}

.ma-btn:disabled {
  opacity: 1;
}

.profile-name-heade {
  border-radius: 50%;
  background-color: #ead79a;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 0.75em; */
  border: 1px solid #fff;
  /* font-weight: 600; */
  /* margin-top: -6px; */
}

.profile-full-name {
  font-size: 0.875em;
}

.profile-inf {
  align-items: center;
}

.mm-menu {
  margin-right: 1.5rem;
}

.ed-menu {
  margin-right: 45px;
}

.mm-menu:hover {
  color: #ffe500;
}

.ed-menu:hover {
  color: #ffe500;
}

.profile-full-name:hover {
  color: #ffe500;
}

.maturity-icon {
  color: #ffe500;
  font-size: 0.9rem;
}

.notification-icon {
  font-size: 2rem;
}

.notification-icon:hover {
  color: #ffe500;
}

.notification-con {
  padding: 0 1rem;
}

/* .notification-con .badge {
    font-size: 56%;
  } */
.notification-count {
  margin-top: -4px;
  margin-left: -10px;
  padding-top: 1.5px;
}

#customeTotgel {
  display: none;
}

.toggle-btn {
  display: none;
}

@media only screen and (max-width: 767px) {
  #dropdown-1 .dropdown-toggle {
    padding: 0;
  }

  .header-container {
    display: block;
    padding-bottom: 30px;
    overflow: hidden;
  }

  .menu-container {
    display: none !important;
  }

  #customeTotgel:checked+.menu-container {
    margin-top: 20px;
    display: block !important;
  }

  .toggle-btn {
    display: block;
    text-align: right;
    font-size: 30px;
    width: 30px;
    margin-left: 100%;
    margin-top: -50px;
  }

  .logo-container {
    padding-left: 0em;
  }

  .img-logo {
    margin-top: 25px;
  }

  .menu-container>div {
    margin-top: 10px;
  }

  /* .header-container{
      display: none;
    } */
  .sub-menu ul {
    text-align: center !important;
  }

  .sub-menu ul li {
    display: block !important;
  }
}

.active-tab-hr {
  border-top: 3px #ffe500 solid;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
  margin-top: 6px;
}

.sub-menu {
  padding-right: 3em;
  padding-left: 3em;
  background: darkgoldenrod;
  border-top: 2px solid darkgoldenrod;
}

.sub-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: right;
}

.sub-menu ul li {
  display: inline-block;
  color: #fff;
  padding: 8px 5px;
  margin: 0px 18px;
  cursor: pointer;
}

.sub-menu .single-item {
  display: inline-block;
  color: #fff;
  padding: 8px 5px;
  margin: 0px 18px;
  cursor: pointer;
}

.sub-menu .single-item div {
  width: max-content;
}

.active-down {
  font-size: 0.9rem !important;
  margin-left: 5px;
}

.profile-da {
  font-size: 0.9rem !important;
  color: #ffe500;
  margin-left: 0.2rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hide-notf-cn {
  opacity: 0;
}

#reports-container {
  display: flex;
  height: 6rem;
  justify-content: center;
}

#reports {
  display: flex;
  height: 4rem;
  overflow: hidden;
}

.arrows {
  display: flex;
  width: 3rem;
  height: 4rem;
  align-items: center;
}

#reports .single-item {
  width: 15%;
  height: 4rem;
  text-align: center;
}



@media only screen and (min-width: 1800px) {

  #reports-container {
    justify-content: end;
  }

  #reports-container .arrows {
    display: none;
  }

}
</style>
